<template>
  <div>
    <b-card>
      <b-row class="d-flex align-items-center">
        <b-col cols="12" lg="3">
          <b-form-group label="Select Range From">
            <flat-pickr
              id="start_date"
              v-model="form.start_date"
              class="form-control"
              name="start_date"
              placeholder="Select Start Date"
              :config="fromDateConfig"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="3">
          <b-form-group label="Select Range To">
            <flat-pickr
              id="end_date"
              v-model="form.end_date"
              class="form-control"
              name="end_date"
              placeholder="Select End Date"
              :config="toDateConfig"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="4">
          <b-form-group label="Select System">
            <VueSelect
              v-model="selectedSystem"
              placeholder="Select System"
              :options="systemOptions"
              @input="resetTable"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="2">
          <b-button
            variant="primary"
            class="w-100"
            style="margin-top: 13px"
            :disabled="isLoading || checkFillables"
            @click="handleSubmit"
          >
            <span v-if="isLoading">
              <b-spinner small />
            </span>
            <span v-else>Extract</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <MyTable
        :title="selectedSystem ? selectedSystem.label : ''"
        :columns="columns"
        :rows="rows"
        :is-loading="isLoading"
        :has-export="true"
        :exporting="isExporting"
        @handleExport="handleReportExport"
      >
        <template v-if="selectedSystem && selectedSystem.value === 1" v-slot="{ props }">
          <span v-if="props.column.field === 'reference_id'">
            <b-button variant="primary" class="w-100" :to="`/order-details/${props.row.id}`">
              {{ props.row.reference_id }}
            </b-button>
          </span>

          <span v-else-if="props.column.field === 'delivery_id'">
            <span>{{ props.row.delivery ? props.row.delivery.id : '-' }}</span>
          </span>

          <span v-else-if="props.column.field === 'status'">
            <span>
              <b-badge :variant="props.row.status.color_name" class="w-100 p-1 font-weight-bolder">
                {{ getStatusText(props.row.status) }}
              </b-badge>
            </span>
          </span>

          <span v-else-if="props.column.field === 'po_expiry_date'">
            <span>{{ $helpers.formatDateTime(props.row.po_expiry_date) }}</span>
          </span>

          <span v-else-if="props.column.field === 'date_added'">
            <span>{{ $helpers.formatDateTime(props.row.date_added) }}</span>
          </span>

          <span v-else-if="props.column.field === 'delivery_date'">
            <span>{{ $helpers.formatDateTime(props.row.delivery_date) }}</span>
          </span>

          <span v-else-if="props.column.field === 'sub_total'">
            <span>{{ $helpers.formatTotal(props.row.sub_total) }}</span>
          </span>

          <span v-else-if="props.column.field === 'pickup_charge'">
            <span>{{ $helpers.formatTotal(props.row.pickup_charge) }}</span>
          </span>

          <span v-else-if="props.column.field === 'voucher_discount'">
            <span>{{ $helpers.formatTotal(props.row.voucher_discount) }}</span>
          </span>

          <span v-else-if="props.column.field === 'grand_total' && selectedSystem != 2">
            <span>{{ $helpers.formatTotal(props.row.grand_total) }}</span>
          </span>

          <span v-else-if="props.column.field === 'total_earn'">
            <span>{{ $helpers.formatTotal(props.row.grand_total - props.row.golog_cut) }}</span>
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <template v-else-if="selectedSystem && selectedSystem.value === 2" v-slot="{ props }">
          <span v-if="props.column.field === 'order_id'">
            <b-button
              variant="primary"
              class="w-100"
              :to="`/chartered-truck/order-details/${props.row.id}`"
            >
              {{ props.row.id }}
            </b-button>
          </span>

          <span v-else-if="props.column.field === 'delivery_id'">
            <span>{{ props.row.delivery ? props.row.delivery.id : '-' }}</span>
          </span>

          <span v-else-if="props.column.field === 'status'">
            <span>
              <b-badge :variant="props.row.status.color_name" class="w-100 p-1 font-weight-bolder">
                {{ getStatusText(props.row.status) }}
              </b-badge>
            </span>
          </span>

          <span v-else-if="props.column.field === 'user_id'">
            <span>{{ props.row.driver ? props.row.driver.company.id : '-' }}</span>
          </span>

          <span v-else-if="props.column.field === 'user_name'">
            <span>{{ props.row.driver ? props.row.driver.company.name : '-' }}</span>
          </span>

          <span v-else-if="props.column.field === 'vehicle_driver'">
            <span>{{ props.row.driver ? props.row.driver.full_name : '-' }}</span>
          </span>

          <span v-else-if="props.column.field === 'po_expiry_date'">
            <span>{{ $helpers.formatDateTime(props.row.po_expiry_date) }}</span>
          </span>

          <span v-else-if="props.column.field === 'delivery_date'">
            <span>{{ $helpers.formatDateTime(props.row.delivery_date) }}</span>
          </span>

          <span v-else-if="props.column.field === 'sub_total'">
            <span>{{ $helpers.formatTotal(props.row.sub_total) }}</span>
          </span>

          <span v-else-if="props.column.field === 'pickup_charge'">
            <span>{{ $helpers.formatTotal(props.row.pickup_charge) }}</span>
          </span>

          <span v-else-if="props.column.field === 'total_earn'">
            <span>{{ $helpers.formatTotal(props.row.smarttruck_earn) }}</span>
          </span>

          <span v-else-if="props.column.field === 'remarks'">
            <span>{{ props.row.remarks ? props.row.remarks : '-' }}</span>
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <template v-else v-slot="{ props }">
          <span v-if="props.column.field === 'delivery_id'">
            <span>{{ props.row.delivery ? props.row.delivery.id : '-' }}</span>
          </span>

          <span v-else-if="props.column.field === 'order_date'">
            <span>{{ $helpers.formatDate(props.row.date_added) }}</span>
          </span>

          <span v-else-if="props.column.field === 'statusFREIGHT'">
            <span>
              <b-badge :variant="props.row.status.color_name" class="w-100 p-1 font-weight-bolder">
                {{ getStatusText(props.row.status) }}
              </b-badge>
            </span>
          </span>

          <span v-else-if="props.column.field === 'delivery_date_time'">
            <span>{{ $helpers.formatDateTime(props.row.delivery_date) }}</span>
          </span>

          <span v-else-if="props.column.field === 'po_expiry_date'">
            <span>{{ $helpers.formatDateTime(props.row.po_expiry_date) }}</span>
          </span>

          <span v-else-if="props.column.field === 'vehicle_plate'">
            <span>{{ props.row.vehicle ? props.row.vehicle.plate : '-' }}</span>
          </span>

          <span v-else-if="props.column.field === 'vehicle_driver'">
            <span>{{ props.row.driver ? props.row.driver.full_name : '-' }}</span>
          </span>

          <span v-else-if="props.column.field === 'user_name'">
            <span>{{ props.row.driver ? props.row.driver.company.name : '-' }}</span>
          </span>

          <span v-else-if="props.column.field === 'sub'">
            <span>{{ $helpers.formatTotal(props.row.sub_total) }}</span>
          </span>

          <span v-else-if="props.column.field === 'pickup'">
            <span>{{ $helpers.formatTotal(props.row.pickup_charge) }}</span>
          </span>

          <span v-else-if="props.column.field === 'voucher_discount'">
            <span>{{ $helpers.formatTotal(props.row.voucher_discount) }}</span>
          </span>

          <span v-else-if="props.column.field === 'grand'">
            <span>{{ $helpers.formatTotal(props.row.grand_total) }}</span>
          </span>

          <span v-else-if="props.column.field === 'total_earn'">
            <span v-if="selectedSystem.value === 3">
              {{ $helpers.formatTotal(props.row.grand_total - props.row.sub_total) }}
            </span>
            <span v-else>{{
              $helpers.formatTotal(props.row.grand_total - props.row.golog_cut)
            }}</span>
          </span>

          <span v-if="props.column.field === 'po_expiry'">
            <span v-if="selectedSystem.value === 0">
              {{ $helpers.formatDateTime(props.row.po_expiry_date) }}
            </span>
          </span>

          <span v-else-if="props.column.field === 'reference_id'">
            <span v-if="selectedSystem.value === 3">
              <b-button
                variant="primary"
                class="w-100"
                :to="`/freight-forwarding/order-details/${props.row.id}`"
              >
                {{ props.row.id }}
              </b-button>
            </span>
          </span>

          <span v-if="props.column.field === 'smarttruck_name'">
            <span v-if="selectedSystem.value === 0 || selectedSystem.value === 3">
              {{ props.row.smarttruck ? props.row.smarttruck.name : '-' }}
            </span>
          </span>

          <span v-if="props.column.field === 'reference_id'">
            <span v-if="selectedSystem.value === 0">
              <b-button variant="primary" class="w-100" disabled>
                {{ props.row.reference_id }}
              </b-button>
            </span>
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </MyTable>
    </b-card>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'
import flatPickr from 'vue-flatpickr-component'
import VueSelect from 'vue-select'

export default {
  name: 'MyReports',
  components: {
    MyTable,
    flatPickr,
    VueSelect,
  },
  data() {
    const columnsB2bpro = [
      { field: 'reference_id', label: 'Order ID', thClass: 'align-middle' },
      {
        field: 'delivery_id',
        label: 'Delivery ID',
        thClass: 'align-middle',
        type: 'number',
      },
      {
        field: 'status',
        label: 'Status',
        thClass: 'align-middle',
      },
      {
        field: 'user.id',
        label: 'User ID',
        thClass: 'align-middle',
        type: 'number',
      },
      { field: 'user.name', label: "Company's Name", thClass: 'align-middle' },
      { field: 'po_expiry_date', label: 'PO Expiry Date', thClass: 'align-middle' },
      { field: 'date_added', label: 'Order Date', thClass: 'align-middle' },
      { field: 'delivery_date', label: 'Delivery Date and Time', thClass: 'align-middle' },
      {
        field: 'sender.name',
        label: "Sender's Name",
        thClass: 'align-middle',
        sortable: false,
      },
      {
        field: 'sender.complete',
        label: "Sender's Address",
        thClass: 'align-middle',
        sortable: false,
      },
      {
        field: 'sender.postcode',
        label: "Sender's Postcode",
        thClass: 'align-middle',
        sortable: false,
      },
      {
        field: 'recipient.name',
        label: "Recipient's Name",
        thClass: 'align-middle',
        sortable: false,
      },
      {
        field: 'recipient.complete',
        label: "Recipient's Address",
        thClass: 'align-middle',
        sortable: false,
      },
      {
        field: 'recipient.postcode',
        label: "Recipient's Postcode",
        thClass: 'align-middle',
        sortable: false,
      },
      {
        field: 'quantity',
        label: 'Quantity',
        thClass: 'align-middle text-left',
        tdClass: 'align-middle text-left',
        type: 'number',
      },
      {
        field: 'sub_total',
        label: 'Subtotal',
        thClass: 'align-middle text-left',
        tdClass: 'align-middle text-left',
        type: 'number',
      },
      {
        field: 'pickup_charge',
        label: 'Pickup Charge',
        thClass: 'align-middle text-left',
        tdClass: 'align-middle text-left',
        type: 'number',
      },
      {
        field: 'voucher_discount',
        label: 'Voucher Discount',
        thClass: 'align-middle text-left',
        tdClass: 'align-middle text-left',
        type: 'number',
      },
      {
        field: 'grand_total',
        label: 'Grand Total',
        thClass: 'align-middle text-left',
        tdClass: 'align-middle text-left',
        type: 'number',
      },
      {
        field: 'total_earn',
        label: 'Total Earn',
        thClass: 'align-middle text-left',
        tdClass: 'align-middle text-left',
        type: 'number',
      },
    ]

    const columnsDefault = [
      { field: 'reference_id', label: 'Order ID', thClass: 'align-middle' },
      {
        field: 'statusFREIGHT',
        label: 'Status',
        thClass: 'align-middle',
      },
      {
        field: 'delivery_id',
        label: 'Delivery ID',
        thClass: 'align-middle text-left',
        tdClass: 'align-middle text-left',
        type: 'number',
      },
      { field: 'user.name', label: "B2BPro Company's Name", thClass: 'align-middle' },
      { field: 'po_expiry', label: 'PO Expiry Date', thClass: 'align-middle' },
      { field: 'order_date', label: 'Order Date', thClass: 'align-middle' },
      { field: 'delivery_date_time', label: 'Delivery Time and Date', thClass: 'align-middle' },
      {
        field: 'sender.name',
        label: "Sender's Name",
        thClass: 'align-middle',
        sortable: false,
      },
      {
        field: 'sender.complete',
        label: "Sender's Address",
        thClass: 'align-middle',
        sortable: false,
      },
      {
        field: 'sender.postcode',
        label: "Sender's Postcode",
        thClass: 'align-middle',
        sortable: false,
      },
      {
        field: 'recipient.name',
        label: "Recipient's Name",
        thClass: 'align-middle',
        sortable: false,
      },
      {
        field: 'recipient.complete',
        label: "Recipient's Address",
        thClass: 'align-middle',
        sortable: false,
      },
      {
        field: 'recipient.postcode',
        label: "Recipient's Postcode",
        thClass: 'align-middle',
        sortable: false,
      },
      { field: 'smarttruck_name', label: "Smarttruck Company's Name", thClass: 'align-middle' },
      { field: 'vehicle_plate', label: 'Vehicle Plate No.', thClass: 'align-middle' },
      { field: 'vehicle_driver', label: 'My Driver', thClass: 'align-middle' },
      {
        field: 'sub',
        label: 'Subtotal',
        thClass: 'align-middle text-left',
        tdClass: 'align-middle text-left',
        type: 'number',
      },
      {
        field: 'pickup',
        label: 'Pickup Charge',
        thClass: 'align-middle text-left',
        tdClass: 'align-middle text-left',
        type: 'number',
      },
      {
        field: 'voucher_discount',
        label: 'Voucher Discount',
        thClass: 'align-middle text-left',
        tdClass: 'align-middle text-left',
        type: 'number',
      },
      {
        field: 'grand',
        label: 'Grand Total',
        thClass: 'align-middle text-left',
        tdClass: 'align-middle text-left',
        type: 'number',
      },
      {
        field: 'total_earn',
        label: 'Total Earn',
        thClass: 'align-middle text-left',
        tdClass: 'align-middle text-left',
        type: 'number',
      },
    ]

    const columnsCharteredtruck = [
      { field: 'order_id', label: 'Order ID', thClass: 'align-middle' },
      {
        field: 'status',
        label: 'Status',
        thClass: 'align-middle',
      },
      {
        field: 'sender.name',
        label: "Sender's Name",
        thClass: 'align-middle',
        sortable: false,
      },
      {
        field: 'sender.complete',
        label: "Sender's Address",
        thClass: 'align-middle',
        sortable: false,
      },
      {
        field: 'total_quantity',
        label: ' Total Quantity',
        thClass: 'align-middle text-left',
        tdClass: 'align-middle text-left',
        type: 'number',
      },
      {
        field: 'total_items',
        label: ' Total Item',
        thClass: 'align-middle text-left',
        tdClass: 'align-middle text-left',
        type: 'number',
      },
      {
        field: 'sub_total',
        label: ' Total Price',
        thClass: 'align-middle text-left',
        tdClass: 'align-middle text-left',
        type: 'number',
      },
      {
        field: 'remarks',
        label: 'Remarks',
        thClass: 'align-middle text-left',
        tdClass: 'align-middle text-left',
      },
    ]

    const systemOptions = [
      { value: 0, label: 'All', text: 'ALL' },
      { value: 1, label: 'B2BPro', text: 'B2B' },
      { value: 2, label: 'Chartered Truck', text: 'CHARTERED' },
      { value: 3, label: 'Freight Forwarding', text: 'FLEET' },
    ]

    return {
      columnsB2bpro,
      columnsDefault,
      columnsCharteredtruck,
      systemOptions,
      selectedSystem: null,
      columns: [],
      rows: [],
      isLoading: false,
      isExporting: false,
      hasExport: false,
      form: {
        start_date: '',
        end_date: '',
      },
    }
  },
  computed: {
    fromDateConfig() {
      return {
        enableTime: false,
        dateFormat: 'Y-m-d',
        altFormat: 'D F j, Y',
        altInput: true,
      }
    },
    toDateConfig() {
      return {
        enableTime: false,
        dateFormat: 'Y-m-d',
        minDate: this.form.start_date ? this.form.start_date : Date.now(),
        altFormat: 'D F j, Y',
        altInput: true,
      }
    },
    checkFillables() {
      return this.form.start_date && this.form.end_date && !this.selectedSystem
    },
  },
  methods: {
    async fetchOrders() {
      this.selectedSystem
      this.isLoading = true
      const from = this.form.start_date
      const to = this.form.end_date
      let query = ''
      if (this.selectedSystem.value === 0) {
        query = `order_type&from=${from}&to=${to}`
      } else {
        query = `order_type=${this.selectedSystem.text}&from=${from}&to=${to}`
      }
      const response = await this.$http.get(`order-reports?${query}`)
      this.rows = response.data
      this.handleSystemChange()
      this.isLoading = false
    },
    handleSystemChange() {
      const system = this.selectedSystem ? this.selectedSystem.value : 0
      switch (system) {
        case 1:
          this.columns = this.columnsB2bpro
          break
        case 2:
          this.columns = this.columnsCharteredtruck
          break
        default:
          this.columns = this.columnsDefault
          break
      }
    },
    getStatusText(order) {
      let statusText
      switch (order.value) {
        case 17:
          statusText = 'For Quote'
          break
        case 12:
          statusText = 'Quoted'
          break
        case 2:
          statusText = 'Accepted'
          break
        case 3:
          statusText = 'Picked Up'
          break
        case 4:
          statusText = 'Arrived at Origin'
          break
        case 5:
          statusText = 'In Transit'
          break
        case 6:
          statusText = 'Arrived at Destination Port'
          break
        case 7:
          statusText = 'Out For Delivery'
          break
        case 8:
          statusText = 'Delivered'
          break
        case 9:
          statusText = 'Request For Cancellation'
          break
        case 10:
          statusText = 'Cancelled'
          break
        case 14:
          statusText = 'Pending Payment'
          break
        default:
          statusText = order.text
      }
      return statusText
    },
    handleSubmit() {
      this.fetchOrders()
    },
    resetTable() {
      this.columns = []
      this.rows = []
    },
    async handleReportExport(type) {
      this.exporting = true
      const from = this.form.start_date
      const to = this.form.end_date
      const query = `export?from=${from}&to=${to}&file_type=${type}&report_type=${this.selectedSystem.text}`
      const response = await this.$http.get(`order-reports/${query}`, { responseType: 'blob' })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute(
        'download',
        `${this.selectedSystem.text}-report-${new Date().getTime()}.${type}`
      )
      document.body.appendChild(fileLink)
      fileLink.click()
      this.exporting = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
